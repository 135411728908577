import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a href={node.data.uri} target="_blank">
          {node.content[0].value}
        </a>
      )
    },
  },
}

const Hero = ({ data }) => {
  const { section4 } = data
  return (
    <div className="community_section">
      <div className="container">
        <div className="community_support">{renderRichText(section4, options)}</div>
      </div>
    </div>
  )
}

export default Hero
