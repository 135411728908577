import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/industry/hero"
import Revenue from "../components/industry/revenue"
import Community from "../components/industry/community"

const Industry = ({ data }) => (
  <div className="industryPage">
    <Layout>
      <Seo title="Industry" />
      <div className="main_content_wrapper">
        <Hero data={data.contentfulIndustry} />
        <Revenue data={data.contentfulIndustry} />
        <Community data={data.contentfulIndustry} />
      </div>
    </Layout>
  </div>
)

export default Industry

export const query = graphql`
  query Industry($language: String) {
    contentfulIndustry(node_locale: { eq: $language }) {
      section1Title      

      section2Title
      section2ShortText
      section2Content {
        section2Content
      }
      section2Image {
        gatsbyImageData
      }

      section4 {
        raw
      }
    }
  }
`
