import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import revenueImg from "../../images/advertising-revenue.png"

const Revenue = ({ data }) => {
  const { section2Title, section2ShortText, section2Content, section2Image } =
    data
  return (
    <div className="net_revenue_section">
      <div className="container">
        <div className="revenue_block">
          <div className="revenue_left">
            <div className="revenue_left_inner">
              <div className="commn_title_label">
                <p>{section2ShortText}</p>
              </div>
              <div className="common_inner_title">
                <h3>{section2Title}</h3>
              </div>
              <p className="info">{section2Content.section2Content}</p>
            </div>
          </div>
          <div className="revenue_right">
            <div className="revenure_chart">
              <GatsbyImage
                image={getImage(section2Image)}
                alt="Chart"
                style={{ display: "none" }}
              />
              <img src={revenueImg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Revenue
